<template>
    <div class="container">
    <div class="email-capture" :class="{ 'email-capture-padded': padded }">
        <div class="email-capture-body">
            <div>
                <h2 class="h2 text-white">{{ title }}</h2>
                <p class="!my-0 p text-white">{{ description }}</p>
            </div>

            <div class="max-w-xs w-full">
                <div v-if="success" class="bg-white py-7 px-5 text-lab-blue text-md text-center">
                    <div v-html="success" class="text-blue"></div>
                </div>

                <template v-else>
                    <div v-if="error" class="text-lab-red-500">{{ error }}</div>

                    <form method="post" action @submit.prevent="submit">
                        <div class="h-16 flex items-stretch border border-lab-red">
                            <template v-for="field in fields">
                                <input v-if="field.type == 'honeypot'" type="text" v-model="values['input_' + field.id]" class="sr-only" />
                                <input
                                    v-else
                                    v-bind="fieldAttrs(field)"
                                    v-model="values['input_' + field.id]"
                                    :disabled="loading"
                                    class="flex-grow text-[17px] font-headline text-lab-gray-800 py-4 px-7 bg-white border-0 ring-0 placeholder:uppercase placeholder:text-lab-gray-800 tracking-wide"
                                />
                            </template>

                            <button type="submit" class="flex items-center justify-center bg-lab-red border-l transition-colors border-lab-red text-white hover:text-lab-red hover:bg-white w-16" :disabled="loading">
                                <div v-if="loading"><LoadingIcon class="size-4" /></div>
                                <arrow-right-icon v-else class="w-6"></arrow-right-icon>
                            </button>
                        </div>
                    </form>
                </template>
            </div>
        </div>

        <div class="email-capture-backer"></div>
    </div>
</div>
</template>

<script setup>
import { ref } from 'vue';
import LoadingIcon from './LoadingIcon.vue';
import axios from 'axios';

const props = defineProps({ 
    form: { type: Object, required: true },
    padded: { type: Boolean, default: true },
});

const values = ref({});

const fields = ref(props.form.fields);
const title = ref(props.form.title);
const description = ref(props.form.description);

const loading = ref(false);
const error = ref(false);
const success = ref(false);

function fieldAttrs(field) {
    let attrs = {
        type: field.type,
        label: field.label,
        required: field.isRequired,
        placeholder: field.placeholder,
    };

    if (field.autocompleteAttribute) {
        attrs.autocomplete = field.autocompleteAttribute;
    }

    return attrs;
}

/**
 * Submit the form data to the REST api endpoint.
 */
function submit() {
    loading.value = true;
    clearErrors();

    axios
        .post('/wp-json/lab/v1/email-capture', values.value)
        .then(({ data }) => handleResponse(data))
        .catch((err) => handleUnknownError(err))
        .then(() => (loading.value = false));
}

/**
 * Handles a non-error response.
 *
 * @param {Object} data
 */
function handleResponse(data) {
    if (!data.is_valid) {
        if (data.validation_messages) {
            error.value = 'Please see the highlighted errors below:';
            handleValidationErrors(data.validation_messages);
        } else {
            handleUnknownError(data);
        }
    } else {
        success.value = data.confirmation_message;
    }
}

/**
 * Clear all errors.
 */
function clearErrors() {
    error.value = false;
    fields.value.forEach((field) => (field.error = null));
}

/**
 * Add an error to an individual fields.
 *
 * @param {Number} id
 * @param {String} message
 */
function addFieldError(id, message) {
    const field = fields.value.findIndex((f) => f.id == id);

    fields.value[field].error = message;
}

/**
 * Handle validation-level errors.
 *
 * @param {Object} errors
 */
function handleValidationErrors(errors) {
    Object.keys(errors).forEach((key) => addFieldError(key, errors[key]));
}

/**
 * Handle an unexpected error.
 *
 * @param {*} error
 */
function handleUnknownError(error) {
    error.value = 'An unknown error has occurred.';
}
</script>

<style>
.email-capture {
    @apply container max-w-6xl mx-auto py-16 md:py-20 px-8 bg-gradient-to-r relative to-[#6abdff] from-[#067AB9];
}

.email-capture-padded {
    @apply my-12 md:my-16 lg:my-20;
}

.email-capture-backer {
    @apply absolute top-0 left-0 size-full bg-cover mix-blend-multiply;
    background-image: url('/app/themes/lab/img/blueprint.png');
    background-position-y: 40%;
}

.email-capture-body {
    @apply max-w-[937px] mx-auto text-center md:text-left relative z-20 text-white flex flex-col md:flex-row justify-center md:justify-between items-center md:items-start gap-y-8 md:gap-y-0 md:gap-x-10;
}

.email-capture-body {
    @apply relative z-20;
}
</style>
