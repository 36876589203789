<template>
    <a class="relative block ring-2 ring-transparent ring-offset-2 transition-all hover:ring-blue-100 bg-blue-100 p-4 rounded-lg group" :class="class" href @click.prevent="open">
        <img :src="thumbnail" :alt="label" class="h-auto w-full" />

        <div class="absolute left-4 top-4 right-4 bottom-4 z-10 flex flex-col items-center justify-end bg-gradient-to-t from-[#01ac93] to-[#B2D99F]/40">
            <span class="p-3 text-white group-hover:pb-5 transition-all">{{ label }}</span>
        </div>
    </a>

    <TransitionRoot as="template" :show="isOpen">
        <Dialog class="relative z-50" @close="close">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-lab-gray-900/75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel class="relative transform overflow-hidden p-3 text-left shadow-xl transition-all sm:mx-8 sm:my-8 sm:w-full sm:max-w-7xl sm:p-0">
                            <div>
                                <div class="flex justify-end">
                                    <button type="button" @click.prevent="close" class="relative z-20 mb-3 flex size-8 items-center justify-center rounded-md bg-white text-lab-gray-700 shadow-xl hover:text-lab-red">
                                        <XMarkIcon class="size-5 transition-colors" />
                                    </button>
                                </div>

                                <div class="aspect-h-9 aspect-w-16 bg-lab-gray-900">
                                    <iframe
                                        :src="'https://www.youtube.com/embed/' + videoId"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        class="h-full w-full"
                                    ></iframe>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { TransitionChild, DialogPanel, Dialog, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { ref } from 'vue';

const props = defineProps({
    thumbnail: { type: String, required: true },
    label: { type: String, required: true },
    class: { type: [String, Array, Object], default: () => [] },
    videoId: { type: String, required: true },
});

const isOpen = ref(false);

function open() {
    isOpen.value = true;
}

function close() {
    isOpen.value = false;
}
</script>
