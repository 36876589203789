<template>
    <div class="mt-8">
        <nav class="flex gap-x-16 border-b border-black/15">
            <a v-for="(item, idx) in items" :href="'#' + item.id" :class="{ active: idx == activeIndex }" @click.prevent="activeIndex = idx">{{ item.name }}</a>
        </nav>

        <div class="relative mt-9 h-screen transition-all" :style="{ maxHeight: currentHeight + 'px' }">
            <div id="dashboardCarouselBanks" ref="banksEl" :class="{ active: activeIndex == 0 }">
                <slot name="banks"></slot>
            </div>

            <div id="dashboardCarouselInsurance" ref="insuranceEl" :class="{ active: activeIndex == 1 }">
                <slot name="insurance"></slot>
            </div>

            <div id="dashboardCarouselDistributors" ref="distributorsEl" :class="{ active: activeIndex == 2 }">
                <slot name="distributors"></slot>
            </div>

            <div id="dashboardCarouselUtilities" ref="utilitiesEl" :class="{ active: activeIndex == 3 }">
                <slot name="utilities"></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

const banksEl = ref(null);
const insuranceEl = ref(null);
const distributorsEl = ref(null);
const utilitiesEl = ref(null);

const items = [
    {
        id: 'banks',
        name: 'Banks & CUs',
        el: banksEl,
    },
    {
        id: 'insurance',
        name: 'Insurance',
        el: insuranceEl,
    },
    {
        id: 'distributors',
        name: 'Distributors & Manufactures',
        el: distributorsEl,
    },
    {
        id: 'utilities',
        name: 'Utilities',
        el: utilitiesEl,
    },
];

const activeIndex = ref(0);
const currentHeight = ref(0);

watch(activeIndex, (incomingIdx) => {
    updateHeight(incomingIdx);
});

onMounted(() => {
    updateHeight(0);
});

function updateHeight(idx) {
    const targetEl = items[idx].el;

    currentHeight.value = targetEl.value.getBoundingClientRect().height;
}
</script>

<style scoped>
a {
    @apply text-lab-gray-500 border-b-4 border-white py-2 text-[18px] font-bold uppercase transition-all duration-200 hover:text-lab-gray-800;
}

a.active {
    @apply text-lab-gray-800;

    border-color: var(--primary-color);
}

div[id*='dashboardCarousel'] {
    @apply absolute left-0 top-0 opacity-0 transition-opacity duration-200;
}

div[id*='dashboardCarousel'].active {
    @apply opacity-100;
}
</style>
