import '../styles/lab.css';

// import and instantiate vue 3
import { createApp } from 'vue';
import ArrowRightIcon from '../img/icons/arrow-right.svg';
import LogoMark from '../img/logo-mark.svg';
import ContactForm from './components/Contact/ContactForm.vue';
import EmailCaptureForm from './components/Common/EmailCaptureForm.vue';
import CatalogRequestForm from './components/Catalogs/CatalogRequestForm.vue';
import QuestionAnwerAccordion from './components/FAQ/QuestionAnswerAccordion.vue';
import TaxonomyDropdown from './components/Common/TaxonomyDropdown.vue';
import VerticalTabs from './components/Tabs/VerticalTabs.vue';
import HorizontalTabs from './components/Tabs/HorizontalTabs.vue';
import WhatWeDoTabs from './components/Home/WhatWeDoTabs.vue';
import BeliefsOverlay from './components/Home/BeliefsOverlay.vue';
import SEOLandingNav from './components/SEOLanding/SEOLandingNav.vue';
import KnowledgebaseItemNav from './components/Knowledgebase/KnowledgebaseItemNav.vue';
import LightboxImg from './components/Common/LightboxImg.vue';
import VideosGrid from './components/Home/VideosGrid.vue';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import KBIcon from './components/KnowledgebaseMarketing/KBIcon.vue';
import KBVideo from './components/KnowledgebaseMarketing/KBVideo.vue';
import DashboardTabs from './components/KnowledgebaseMarketing/DashboardTabs.vue';
import KnowledgebaseRequestForm from './components/KnowledgebaseMarketing/KnowledgebaseRequestForm.vue';
import KpiTabs from './components/KnowledgebaseMarketing/KpiTabs.vue';
import KnowledgebaseNav from './components/KnowledgebaseMarketing/KnowledgebaseNav.vue';

import './modules/knowledgebase-marketing/highlight-carousel';

// create vue app on
const app = createApp({
    data() {
        return {
            navOpen: false,
            navIsFixed: false,
            mobileNavOpen: false,
            navInteractiveThreshold: 100,
            pageIsScrolled: false,
            prevScrollPos: 0,
            beliefsOverlayVisible: false,
            beliefsThumbnailVisible: true,
        };
    },
    methods: {
        toggleNav() {
            this.navOpen = !this.navOpen;
        },
        watchScroll() {
            let currentPos = window.scrollY;
            let movingUp = currentPos < this.prevScrollPos;
            this.pageIsScrolled = currentPos > this.navInteractiveThreshold;
            this.navOpen = movingUp && this.pageIsScrolled;
            this.prevScrollPos = currentPos;
        },
        toggleMobileNav() {
            this.mobileNavOpen = !this.mobileNavOpen;

            if (this.mobileNavOpen) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }
        },
        toggleBeliefsOverlay() {
            this.beliefsOverlayVisible = !this.beliefsOverlayVisible;
        },
        hideBeliefsThumbnail() {
            document.cookie = 'hideBeliefsThumbnail=true; max-age=86400';
            this.beliefsThumbnailVisible = false;
        },
        shouldShowBeliefsThumbnail() {
            return document.cookie.indexOf('hideBeliefsThumbnail') === -1;
        },
    },
    computed: {
        navWrapperClass() {
            return {
                '!bg-opacity-100 shadow-sm': (this.pageIsScrolled && this.navIsFixed),
                '-translate-y-28': (!this.navOpen && this.pageIsScrolled && this.navIsFixed),
            };
        },
        navClass() {
            return {
                '': !this.pageIsScrolled,
                '!text-black': this.pageIsScrolled,
            };
        },
    },
    mounted() {
        document.addEventListener('DOMContentLoaded', this.watchscroll);
        window.addEventListener('scroll', this.watchScroll);
        Fancybox.bind('[data-fancybox]');

        this.navIsFixed = document.querySelector('#global-nav')?.classList.contains('fixed');

        if (this.mobileNavOpen) {
            document.body.classList.add('overflow-hidden');
        }

        if (!this.shouldShowBeliefsThumbnail()) {
            this.beliefsThumbnailVisible = false;
        }
    },
});

app.component('catalog-request-form', CatalogRequestForm);
app.component('contact-form', ContactForm);
app.component('email-capture-form', EmailCaptureForm);
app.component('question-answer-accordion', QuestionAnwerAccordion);
app.component('taxonomy-dropdown', TaxonomyDropdown);
app.component('vertical-tabs', VerticalTabs);
app.component('horizontal-tabs', HorizontalTabs);
app.component('what-we-do-tabs', WhatWeDoTabs);
app.component('beliefs-overlay', BeliefsOverlay);
app.component('seo-landing-nav', SEOLandingNav);
app.component('knowledgebase-item-nav', KnowledgebaseItemNav);
app.component('lightbox-img', LightboxImg);
app.component('arrow-right-icon', ArrowRightIcon);
app.component('logo-mark', LogoMark);
app.component('videos-grid', VideosGrid);
app.component('kb-icon', KBIcon);
app.component('kb-dashboard-tabs', DashboardTabs);
app.component('kb-kpi-tabs', KpiTabs);
app.component('kb-request-form', KnowledgebaseRequestForm);
app.component('kb-nav', KnowledgebaseNav);
app.component('kb-video', KBVideo);

// mount vue app
app.mount('#app');
