<template>
    <div class="kb-nav">
        <a v-for="(item, idx) in items" :href="'#' + item.id" class="kb-nav-item" :class="['kb-nav-item-' + item.id, idx == currentIndex ? 'kb-nav-item-active' : '']">
            <KBIcon :type="item.id" class="size-[26px]" />
            <span>{{ item.title }}</span>
        </a>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import KBIcon from './KBIcon.vue';

const props = defineProps({
    items: { type: Array, required: true },
});

const currentIndex = ref(0);

onMounted(() => {
    props.items.forEach((item) => observer.observe(document.querySelector('#' + item.id)));
});

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            const id = entry.target.id;

            if (entry.isIntersecting) {
                currentIndex.value = props.items.findIndex((item) => item.id == id);
            }
        });
    },
    { threshold: 0.5 },
);
</script>
