<template>
    <div class="mx-auto mt-8 max-w-2xl">
        <div class="py-4">
            <template v-if="success">
                <div class="border bg-white px-8 py-6 text-center text-lg text-lab-blue" v-html="success"></div>
            </template>
            <template v-else>
                <div v-if="error" class="text-red-500">{{ error }}</div>

                <form method="post" action @submit.prevent="submit">
                    <div class="grid grid-cols-2 items-end gap-x-8 gap-y-5">
                        <div v-for="field in fields" :class="[field.type == 'email' ? 'col-span-2' : 'col-span-1', { hidden: field.type == 'hidden' || field.type == 'honeypot' }]">
                            <input v-if="field.type == 'honeypot'" type="text" v-model="values['input_' + field.id]" class="sr-only" />
                            <div v-else>
                                <input
                                    v-bind="fieldAttrs(field)"
                                    v-model="values['input_' + field.id]"
                                    :disabled="loading"
                                    class="peer w-full rounded-[86px] border border-lab-gray-300 bg-white px-8 py-4 text-[18px] font-bold leading-tight text-lab-gray-700 placeholder:text-lab-gray-700 focus:outline-none focus:ring-lab-blue"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 flex justify-center">
                        <button type="submit" class="flex w-auto items-center justify-end gap-4 rounded-[86px] border border-none bg-gradient-to-l from-[#3176b7] to-[#62b5e5] px-8 py-4 text-white" :disabled="loading">
                            <div v-if="loading"><LoadingIcon class="mr-2 h-4 w-4" /></div>
                            <span class="text-[18px] font-bold">Submit</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </form>
            </template>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import LoadingIcon from '../Common/LoadingIcon.vue';
import axios from 'axios';

const props = defineProps({
    form: { type: Object, required: true },
});

const values = ref({});

const fields = ref(props.form.fields);

const loading = ref(false);
const error = ref(false);
const success = ref(false);

function fieldAttrs(field) {
    let attrs = {
        type: field.type,
        label: field.label,
        required: field.isRequired,
        placeholder: field.placeholder,
    };

    if (field.autocompleteAttribute) {
        attrs.autocomplete = field.autocompleteAttribute;
    }

    return attrs;
}

/**
 * Submit the form data to the REST api endpoint.
 */
function submit() {
    loading.value = true;
    clearErrors();

    axios
        .post('/wp-json/lab/v1/knowledgebase-request', values.value)
        .then(({ data }) => handleResponse(data))
        .catch((err) => handleUnknownError(err))
        .then(() => (loading.value = false));
}

/**
 * Handles a non-error response.
 *
 * @param {Object} data
 */
function handleResponse(data) {
    if (!data.is_valid) {
        if (data.validation_messages) {
            error.value = 'Please see the highlighted errors below:';
            handleValidationErrors(data.validation_messages);
        } else {
            handleUnknownError(data);
        }
    } else {
        success.value = data.confirmation_message;
    }
}

/**
 * Clear all errors.
 */
function clearErrors() {
    error.value = false;
    fields.value.forEach((field) => (field.error = null));
}

/**
 * Add an error to an individual fields.
 *
 * @param {Number} id
 * @param {String} message
 */
function addFieldError(id, message) {
    const field = fields.value.findIndex((f) => f.id == id);

    fields.value[field].error = message;
}

/**
 * Handle validation-level errors.
 *
 * @param {Object} errors
 */
function handleValidationErrors(errors) {
    Object.keys(errors).forEach((key) => addFieldError(key, errors[key]));
}

/**
 * Handle an unexpected error.
 *
 * @param {*} error
 */
function handleUnknownError(error) {
    error.value = 'An unknown error has occurred.';
}
</script>
