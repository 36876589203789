<template>
    <div class="grid justify-center gap-5 md:grid-cols-3 lg:gap-8 xl:gap-16">
        <a v-for="(item, index) in items" href @click.prevent="open(index)" class="md:max-w-auto group block max-w-96 bg-white">
            <div class="aspect-video relative bg-lab-gray-900">
                <div class="absolute left-1/2 top-1/2 z-10 h-full w-full -translate-x-1/2 -translate-y-1/2 bg-lab-gray-800 opacity-20 mix-blend-multiply transition-all"></div>

                <div
                    class="absolute left-1/2 top-1/2 z-20 flex w-[55px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-[16px] bg-gradient-to-b from-[#E74040] to-[#9C2323] text-white transition-all group-hover:w-[69px]"
                >
                    <VideoIcon class="ml-1 w-5 py-3 transition-all group-hover:w-7" />
                </div>

                <img :src="item.thumbnail" :alt="item.title" class="relative h-full w-full object-cover transition-opacity group-hover:opacity-75" />
            </div>

            <div class="pb-5 pl-5 pr-8 pt-4 text-base font-bold leading-[1.4] text-lab-gray-800 transition-colors group-hover:text-lab-red">{{ item.title }}</div>
        </a>
    </div>

    <TransitionRoot as="template" :show="isOpen">
        <Dialog class="relative z-50" @close="close">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-lab-gray-900/75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel class="relative transform overflow-hidden p-3 text-left shadow-xl transition-all sm:mx-8 sm:my-8 sm:w-full sm:max-w-7xl sm:p-0">
                            <div>
                                <div class="flex justify-end">
                                    <button type="button" @click.prevent="close" class="relative z-20 mb-3 flex size-8 items-center justify-center rounded-md bg-white text-lab-gray-700 shadow-xl hover:text-lab-red">
                                        <XMarkIcon class="size-5 transition-colors" />
                                    </button>
                                </div>

                                <div class="aspect-h-9 aspect-w-16 bg-lab-gray-900">
                                    <iframe
                                        :src="'https://www.youtube.com/embed/' + activeItem.id"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        class="h-full w-full"
                                    ></iframe>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import VideoIcon from '../../../img/icons/video.svg';
import { ref } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { computed } from 'vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';

const props = defineProps({ items: { type: Array, default: () => [] } });

const isOpen = ref(false);
const active = ref(0);

const activeItem = computed(() => props.items[active.value]);

function open(index) {
    active.value = index;
    isOpen.value = true;
}

function close() {
    isOpen.value = false;
}
</script>
