<template>
    <div class="grid grid-cols-1 sm:grid-cols-4 sm:gap-12 2xl:gap-20">
        <nav class="col-span-1 w-full my-3 sm:my-10 px-4 sm:px-0">
            <ul class="flex sm:flex-col items-start sm:space-y-2 text-nowrap sm:text-wrap flex-wrap gap-1">
                <li class="translate-y-[1px] sm:w-full" v-for="(tab, i) in tabs">
                    <div :class="{ 'bg-white bg-opacity-25 sm:bg-opacity-100 text-lab-gray-600': !tabIsActive(i), 'border-red-600 sm:bg-red-600 text-red-600 sm:text-white': tabIsActive(i) }"
                        class="block w-full px-3 py-2 sm:px-3 sm:py-5 uppercase text-[11px] sm:text-xs font-semibold border sm:border-none hover:bg-red-700 hover:text-white transition-colors cursor-pointer rounded-full sm:rounded-none"
                        v-text="tab.label" @click="setActiveTab(i)">
                    </div>
                </li>
            </ul>
        </nav>

        <template v-for="(tab, i)  in tabs">
            <div class="col-span-3 p-4 sm:px-0 sm:py-12 sm:pr-20 user-content" v-if="tabIsActive(i)">
                <h4 class="mb-2" v-html="tab.headline"></h4>
                <div v-html="tab.content"></div>

                <div class="mt-10 grid grid-cols-2 gap-16" v-if="tab.display_lists">
                    <div class="col-span-1 p-2 sm:p-0" v-for="list in tab.lists">
                        <h4 v-html="list.headline"></h4>
                        <ul>
                            <li v-for="item in list.items" v-text="item.value"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
    tabs: {
        type: Array,
        required: true
    },
});

const activeTab = ref(0);

function tabIsActive(i) {
    return i == activeTab.value;
}

function setActiveTab(i) {
    activeTab.value = i;
}

</script>
