<template>
    <div class="p-10">
        <div>
            <h3 class="font-headline font-medium text-5xl leading-[1em]">{{ headline }}</h3>
            <p class="py-2 font-sans text-sm">{{ content }}</p>
        </div>

        <div class="py-4">
            <template v-if="success">
                <div v-html="success"></div>
            </template>
            <template v-else>
                <div v-if="error" class="text-red-500">{{ error }}</div>

                <form method="post" action @submit.prevent="submit">
                    <div class="grid grid-cols-2 gap-4 items-end">
                        <div v-for="field in fields" class="col-span-1" :class="{'hidden':field.type == 'hidden' || field.type == 'honeypot'}">
                            <input v-if="field.type == 'honeypot'" type="text" v-model="values['input_' + field.id]" class="sr-only" />
                            <div v-else>
                                <label v-if="field.type !== 'hidden'" class="block mb-1 uppercase font-sans text-xs text-neutral-900 font-bold">{{ field.label }} 
                                    <span v-if="field.isRequired" class="pl-1 text-red-500">*</span>
                                </label>
                                <input v-bind="fieldAttrs(field)" v-model="values['input_' + field.id]" :disabled="loading" class="w-full rounded-lg text-neutral-800 bg-white border border-neutral-400 py-2 px-4 text-sm focus:outline-none placeholder-transparent peer" />
                            </div>
                        </div>

                        <div>
                            <button type="submit" class="w-auto flex justify-end gap-4 items-center px-4 py-2 rounded-lg bg-white border border-neutral-400 hover:bg-blue-500 hover:text-white hover:border-lab-blue-600" :disabled="loading">
                                <div v-if="loading"><LoadingIcon class="w-4 h-4 mr-2" /></div>
                                <span class="text-sm">Submit Request</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" /></svg>
                            </button>
                        </div>
                    </div>
                </form>
            </template>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import LoadingIcon from '../Common/LoadingIcon.vue';
import axios from 'axios';

const props = defineProps({ 
    form: { type: Object, required: true } ,
    headline: { type: String, default: 'Helping you build a better business library.' },
    content: { type: String, default: 'Fill out the form below to receive the ebook.' },
    source: { type: String, required: true },
});

const values = ref({});

const fields = ref(props.form.fields);

const loading = ref(false);
const error = ref(false);
const success = ref(false);

fields.value.forEach(function (field) {
    if(field.defaultValue) {
        values.value['input_' + field.id] = (field.defaultValue == '{embed_post:post_title}') ? props.source : field.defaultValue;
    }
})

function fieldAttrs(field) {
    let attrs = {
        type: field.type,
        label: field.label,
        required: field.isRequired,
        placeholder: field.placeholder,
    };

    if (field.autocompleteAttribute) {
        attrs.autocomplete = field.autocompleteAttribute;
    }

    return attrs;
}

/**
 * Submit the form data to the REST api endpoint.
 */
function submit() {
    loading.value = true;
    clearErrors();

    axios
        .post('/wp-json/lab/v1/catalog-request', values.value)
        .then(({ data }) => handleResponse(data))
        .catch((err) => handleUnknownError(err))
        .then(() => (loading.value = false));
}

/**
 * Handles a non-error response.
 *
 * @param {Object} data
 */
function handleResponse(data) {
    if (!data.is_valid) {
        if (data.validation_messages) {
            error.value = 'Please see the highlighted errors below:';
            handleValidationErrors(data.validation_messages);
        } else {
            handleUnknownError(data);
        }
    } else {
        success.value = data.confirmation_message;
    }
}

/**
 * Clear all errors.
 */
function clearErrors() {
    error.value = false;
    fields.value.forEach((field) => (field.error = null));
}

/**
 * Add an error to an individual fields.
 *
 * @param {Number} id
 * @param {String} message
 */
function addFieldError(id, message) {
    const field = fields.value.findIndex((f) => f.id == id);

    fields.value[field].error = message;
}

/**
 * Handle validation-level errors.
 *
 * @param {Object} errors
 */
function handleValidationErrors(errors) {
    Object.keys(errors).forEach((key) => addFieldError(key, errors[key]));
}

/**
 * Handle an unexpected error.
 *
 * @param {*} error
 */
function handleUnknownError(error) {
    error.value = 'An unknown error has occurred.';
}
</script>
