<template>
    <div :class="'kb-icon kb-icon-' + type">
        <component :is="icons[type]"></component>
    </div>
</template>

<script setup>
import IconKpi from '../../../img/icons/kpi.svg';
import IconDashboard from '../../../img/icons/dashboard.svg';
import IconAutomation from '../../../img/icons/automation.svg';
import IconProcess from '../../../img/icons/process.svg';
import IconImprovement from '../../../img/icons/improvement.svg';
import IconAssets from '../../../img/icons/assets.svg';

const props = defineProps({ type: { type: String, required: true } });

const icons = {
    kpi: IconKpi,
    dashboard: IconDashboard,
    automation: IconAutomation,
    process: IconProcess,
    improvement: IconImprovement,
    assets: IconAssets,
};
</script>

<style>
.kb-icon {
    @apply flex shrink-0 items-center justify-center rounded-full bg-red text-white;
}

.kb-icon svg {
    @apply w-[44%];
}

.kb-icon-kpi {
    background: linear-gradient(261.94deg, #f5802e 9.24%, #ffc645 105.41%);
}

.kb-icon-dashboard {
    background: linear-gradient(261.94deg, #67c0f0 9.24%, #8edbe9 105.41%);
}

.kb-icon-automation {
    background: linear-gradient(261.94deg, #01ac93 9.24%, #b2d99f 105.41%);
}
.kb-icon-process {
    background: linear-gradient(261.94deg, #a13aab 9.24%, #e887b6 105.41%);
}
.kb-icon-improvement {
    background: linear-gradient(261.94deg, #f8be41 9.24%, #dada42 105.41%);
}
.kb-icon-assets {
    background: linear-gradient(261.94deg, #e6643b 9.24%, #e7282f 78.36%);
}
</style>
