<template>
    <div class="mt-8">
        <nav class="flex flex-wrap lg:flex-nowrap gap-x-8 md:gap-x-12 xl:gap-x-16 border-b border-black/15 items-start sm:items-end text-center ">
            <a v-for="(item, idx) in items" :href="'#' + item.id" :class="{ active: idx == activeIndex }" @click.prevent="activeIndex = idx">{{ item.name }}</a>
        </nav>

        <div class="relative mt-9 h-screen transition-all" :style="{ maxHeight: currentHeight + 'px' }">
            <div ref="banksEl" :class="{ active: activeIndex == 0 }" class="panel">
                <slot name="banks"></slot>
            </div>

            <div ref="insuranceEl" :class="{ active: activeIndex == 1 }" class="panel">
                <slot name="insurance"></slot>
            </div>

            <div ref="supplyEl" :class="{ active: activeIndex == 2 }" class="panel">
                <slot name="supply"></slot>
            </div>

            <div ref="professionalEl" :class="{ active: activeIndex == 3 }" class="panel">
                <slot name="professional"></slot>
            </div>

            <div ref="utilitiesEl" :class="{ active: activeIndex == 4 }" class="panel">
                <slot name="utilities"></slot>
            </div>

            <div ref="healthcareEl" :class="{ active: activeIndex == 5 }" class="panel">
                <slot name="healthcare"></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

const banksEl = ref(null);
const insuranceEl = ref(null);
const supplyEl = ref(null);
const professionalEl = ref(null);
const utilitiesEl = ref(null);
const healthcareEl = ref(null);

const items = [
    {
        id: 'banks',
        name: 'Banks & Credit Unions',
        el: banksEl,
    },
    {
        id: 'insurance',
        name: 'Insurance',
        el: insuranceEl,
    },
    {
        id: 'supply',
        name: 'Supply Chain',
        el: supplyEl,
    },
    {
        id: 'professional',
        name: 'Professional Services',
        el: professionalEl,
    },
    {
        id: 'utilities',
        name: 'Utilities',
        el: utilitiesEl,
    },
    {
        id: 'healthcare',
        name: 'Healthcare',
        el: healthcareEl,
    }
];

const activeIndex = ref(0);
const currentHeight = ref(0);

watch(activeIndex, (incomingIdx) => {
    updateHeight(incomingIdx);
});

onMounted(() => {
    updateHeight(0);
});

function updateHeight(idx) {
    const targetEl = items[idx].el;

    currentHeight.value = targetEl.value.getBoundingClientRect().height;
}
</script>

<style scoped>
a {
    @apply text-lab-gray-500 border-b-4 border-white py-2 text-[18px] sm:text-xs md:text-sm leading-tight lg:text-[18px] font-bold uppercase transition-all duration-200 hover:text-lab-gray-800;
}

a.active {
    @apply text-lab-gray-800;

    border-color: var(--primary-color);
}

.panel {
    @apply absolute left-0 top-0 opacity-0 transition-opacity duration-200;
}

.panel.active {
    @apply opacity-100;
}
</style>
