<template>
    <div id="knowledgebase-item-nav-wrapper" :class="[navOpen && pageIsScrolled ? 'pt-28' : 'pt-8', 'transition-all duration-500 lg:pb-20']">
        <div class="text-xs uppercase text-[#bcbcbc] font-bold tracking-wide pl-3.5 mb-3">Outline</div>

        <ul id="knowledgebase-item-nav">
            <li v-for="section in sections">
                <a :href="'#' + section.slug">
                    {{ section.label }}
                </a>
            </li>
        </ul>
    </div>
    <!-- 
    <pre>PageIsScrolled: {{ pageIsScrolled }}</pre>
    <pre>NavOpen:        {{ navOpen }}</pre> 
    -->
</template>

<script setup>
import scrollSpy from 'simple-scrollspy';
import { onMounted, ref } from 'vue';
import Sticky from 'sticky-js';

const props = defineProps({
    sections: { type: Array, default: () => [] },
    navOpen: { type: Boolean, default: false },
    pageIsScrolled: { type: Boolean, required: true },
});

const sticky = ref(null);

onMounted(() => {
    scrollSpy(document.getElementById('knowledgebase-item-nav'), {
        sectionClass: '.knowledgebase-item-section',
    });

    sticky.value = new Sticky('#knowledgebase-item-nav-wrapper');
});
</script>

<style>
#knowledgebase-item-nav-wrapper {
    @apply border-b-2 border-b-light/25 pb-8;
}

#knowledgebase-item-nav a {
    @apply inline-block px-3.5 py-2 text-[15px] leading-5  rounded-md transition-all;
}

#knowledgebase-item-nav a.active {
    @apply text-red-600 bg-red-600/10 font-bold;
}
</style>
