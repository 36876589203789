import Glide, { Breakpoints, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.kb-highlights')) {
        new Glide('.kb-highlights', {
            perView: 5,
            type: 'carousel',
            gap: 24,
            peek: 0,
            // autoplay: 1,
            // animationDuration: 8000,
            // animationTimingFunc: 'linear',
            breakpoints: {
                1920: {
                    peek: 56,
                },
                1400: {
                    perView: 4,
                    peek: 56,
                },
                1160: {
                    perView: 3,
                    peek: 42,
                },
                768: {
                    perView: 2,
                    gap: 16,
                    peek: 36,
                },
                520: {
                    perView: 1,
                    peek: 56,
                    gap: 16,
                },
            },
        }).mount({ Breakpoints, Swipe, Autoplay });
    }
});
